import { useLocation } from "wouter"
import style from "./style.module.css"


export default function CardImgFull({ img, name, route, noName,redirect }) {

    const [, navigate] = useLocation()

    return (
        <div className={style.cardImgFull}
            style={{
                backgroundImage: `url("${img}")`,
                backgroundSize: "cover",
                backgroundPosition: "center"
            }}
            onClick={() => redirect?window.open(`${route}`) :navigate(`/section/${route}`)}
        >
            {!noName && <span className={style.cardImgFull_span}>{name}</span>}
        </div>
    )
}
