import React, { useEffect } from 'react'
// import ShoppingPage from '../../components/ShoppingPage';
import { Provider } from 'react-redux'
import Search from 'src/components/Foms/Search'
import Layout from 'src/components/Layout'
import Title from 'src/components/Text/Title'
import store from '../../redux/store'
import useArticlesForCategory from 'src/hooks/useArticlesForCategory';
import Articles from 'src/components/Articles'
import Spinner from 'src/components/Loading'
import Filters from 'src/components/Foms/Filters'
// import MarketingImg from 'src/components/MarketingImg'
import ConsolesBar from 'src/components/Foms/Filters/ConsolesBar'
import style from './style.module.css'

export default function StorePage({ params }) {

    const section = decodeURI(params.id.toUpperCase())

    const { articles, loading } = useArticlesForCategory(section)




    return (
        <Provider store={store}>
            <Layout maxWidth>
                <Search />
                <Title style={{ color: "#000" }}>{section}</Title>
                <Filters section={section} />
                {section === "NSWITCH" && <p className={style.center}>ALQUILER POR 3 O 6 MESES </p>}

                {section === "ALQUILER-PS" && <ConsolesBar />}

                {/* {section === "XONE-XSERIES" && <MarketingImg img="https://firebasestorage.googleapis.com/v0/b/panaobrero.appspot.com/o/luigidigital%2FWhatsApp%20Image%202022-09-16%20at%2000.15.54.jpeg?alt=media&token=ef342a7e-6c7e-4cd3-880f-3abf9a93a64e"/>} */}

                <div>
                    {
                        loading ? <Spinner /> : <>

                            {/* EN ESTA CONDICION SOLO ENTRAN REGISTROS QUE NO SON ALQUILER DE SWITCH Y ALQUILER DE XBOX ONE Y SERIES. */}
                            {
                                section !== "NSWITCH" && section !== "ALQUILER" && <Articles articles={articles} title={section} />
                            }

                            {/* ALQUILER SE REFIERE A JUEGOS DE XBOX ONE Y SERIES EN ALQUILER. */}
                            {
                                section === "ALQUILER" && <div>

                                    <p className={style.center}>ESTRENO</p>
                                    <div>
                                        <Articles articles={articles.filter(y => y.start === 1)} title={section} />
                                    </div>
                                    <p className={style.center}>ESTANDAR</p>
                                    <div>
                                        <Articles articles={articles.filter(y => y.start !== 1)} title={section} />
                                    </div>
                                </div>
                            }

                            {/* NSWITCH SE REFIERE A JUEGOS DE ALQUILER DE SWITCH */}


                            {
                                section === "NSWITCH" && <div>

                                    <div>
                                        <p className={style.center}>ESTRENO 3 MESES $15 </p>
                                        <p className={style.center}>ESTRENO 6 MESES $20</p>
                                    </div>
                                    <div>
                                        <Articles articles={articles[2]} title={'NSWITCH'} />
                                    </div>


                                    <div>
                                        <p className={style.center}>ESTANDAR 3 MESES $5 </p>
                                        <p className={style.center}>ESTANDAR 6 MESES $10</p>
                                    </div>
                                    <div>
                                        <Articles articles={articles[0]} title={'NSWITCH'} />
                                    </div>


                                    <div>
                                        <p className={style.center}>MAS PEDIDOS 3 MESES $10 </p>
                                        <p className={style.center}>MAS PEDIDOS 6 MESES $15</p>
                                    </div>
                                    <div>
                                        <Articles articles={articles[1]} title={'NSWITCH'} />
                                    </div>

                                    {/* {
                                        articles.map((x, index) => <div>
                                            {x[0].brand === "ESTANDAR" ? <>
                                                <p className={style.center}>{x[0].brand} 3 MESES $5 </p>
                                                <p className={style.center}>{x[0].brand} 6 MESES $10</p>
                                            </> : <>

                                                {
                                                    x[0].brand === "ESTRENO" ? <>

                                                    </> : <></>
                                                }

                                            </>}
                                            <div>
                                                <Articles articles={x} title={'NSWITCH'} />
                                            </div>
                                        </div>)
                                    } */}
                                </div>
                            }

                        </>
                    }
                </div>
            </Layout>
        </Provider>
    )
}
