import React from "react";
// import ReactWhatsapp from "react-whatsapp";

import { FloatingWhatsApp } from 'react-floating-whatsapp'
// import "react-floating-whatsapp/dist/index.css";

export default function WhatsappButton() {
    return (
        <>
            {/* <ReactWhatsapp number="%44905366030997" message="Hello World!!!" /> */}
            <FloatingWhatsApp
                accountName="Luigi digital"
                avatar="https://www.luigi-digital.com/IMG/LOGO.png"
                phoneNumber="+5804124010740"
                darkMode={true}
                statusMessage="Tienda de videojuegos"
                chatMessage="Hola, ¿cómo podemos ayudarte?"
            />
        </>
    );
};