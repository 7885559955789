import { useEffect, useState } from 'react'
import articlesServices from '../services/articlesForCategory'

export default function useArticlesForCategory(c) {

    const [articles, setArticles] = useState([])
    const [loading, setLoading] = useState(true)
    const [message, setMessage] = useState('')


    function group(array) {
        // Creamos un objeto para almacenar los elementos agrupados por la propiedad "brand"
        const grupos = {};

        // Recorremos el array de objetos y agrupamos los elementos por la propiedad "brand"
        array.filter(y => y.gender === 'DIGITAL').forEach((obj) => {
            const brand = obj.brand;
            if (!grupos[brand]) {
                grupos[brand] = []; // Creamos un nuevo grupo si aún no existe para esa "brand"
            }
            grupos[brand].push(obj); // Agregamos el objeto al grupo correspondiente
        })

        // Convertimos el objeto de grupos en un array de arrays

        const resultado = Object.values(grupos);
        resultado.sort((a, b) => b.length - a.length);

        return resultado;
    }


    useEffect(() => {
        setLoading(true)
        articlesServices.getArticlesForCategory(c === "NSWITCHF" ? "NSWITCHF" : c)
            .then(initialArticles => {
                if (c === 'NSWITCH') {
                    setArticles(group(initialArticles))
                    setLoading(false)
                } else {
                    setArticles(initialArticles)
                    setLoading(false)
                }
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
                setMessage('Ups, Error de conexión')
            })
    }, [c])


    return { articles, loading, message }
}