import Layout from "src/components/Layout";
import Hero from "../../components/Hero";
import Popular from "../../components/Popular";

export default function Home() {
    return (
        <Layout>
            <Hero />
            <Popular />
        </Layout>

    )
}