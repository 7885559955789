import { useState } from "react";
import { connect } from "react-redux";
import CloseIcon from "src/icons/CloseIcon";
import { useLocation } from "wouter";
import HamburguerMenu from "../../icons/HamburguerMenu";
import ShoppingCart from "../../icons/ShoppingCart";
import Logo from "../Logo";
import style from "./style.module.css"

function Navbar({ inCard }) {



    const items = [
        { name: "FUNKOS", route: "FUNKOS", img: "https://firebasestorage.googleapis.com/v0/b/panaobrero.appspot.com/o/luigidigital%2FFUNK.jpeg?alt=media&token=e81d761a-b42f-452c-aba7-50c6c3fcea5a" },
        { name: "PS4 Y PS5", route: "PS4", img: "https://firebasestorage.googleapis.com/v0/b/panaobrero.appspot.com/o/luigidigital%2Fps4_1x.webp?alt=media&token=be168af4-303a-4441-82bf-5049b1603e6a" },
        { name: "XBOX ONE Y SERIES S/X", route: "XONE-XSERIES", img: "https://firebasestorage.googleapis.com/v0/b/panaobrero.appspot.com/o/luigidigital%2Fxbox%20one%20xbox%20series.jpg?alt=media&token=62759a3d-5f38-4b05-a596-69ad9c7b47ef" },
        { name: "ALQUILER DE JUEGOS", route: "XONE-XSERIES", img: "https://firebasestorage.googleapis.com/v0/b/panaobrero.appspot.com/o/luigidigital%2Fxbox%20one%20xbox%20series.jpg?alt=media&token=62759a3d-5f38-4b05-a596-69ad9c7b47ef" },
        { name: "NINTENDO SWITCH", route: "NSWITCH", img: "https://firebasestorage.googleapis.com/v0/b/panaobrero.appspot.com/o/luigidigital%2Fimage_processing20200908-12254-1eumqhh.png?alt=media&token=f30c2207-f1ea-435f-b1b9-8e8c2f114629" },
        { name: "JUEGOS RETRO", route: "JRETRO", img: "https://firebasestorage.googleapis.com/v0/b/panaobrero.appspot.com/o/luigidigital%2F4a1cacbe919300b15645482f7cac3aad.webp?alt=media&token=60215bb9-959a-4184-8876-673e8c2d7e42" },
        { name: "CONTROLES Y ACCESORIOS", route: "CONTROLES-ACCESORIOS", img: "https://firebasestorage.googleapis.com/v0/b/panaobrero.appspot.com/o/luigidigital%2F2d5a5dabcd9587ee812bd4a0135c1ac6.webp?alt=media&token=2e4597eb-25a2-4f6d-a135-7ab3aa27b0d3" },
        { name: "CONSOLAS", route: "CONSOLAS", img: "https://firebasestorage.googleapis.com/v0/b/mazasocial.appspot.com/o/CONSOLAS.webp?alt=media&token=9dfea89e-e458-4064-b646-669a0884ae77" },
    ]

    const [, navigate] = useLocation()


    const [show, setShow] = useState(false)

    const handleClick = (route) => {
        setShow(false)
        navigate(`/section/${route}`)
    }

    return (
        <nav className={style.navbar}>

            <div  >
                <span className={style.navbar_item} onClick={() => setShow(prev => !prev)} >
                    <HamburguerMenu fill="#fff" width={25} height={25} />
                </span>
            </div>
            <div onClick={() => navigate('/')}><Logo /></div>
            <div onClick={() => navigate('/Cart')}>
                <span className={style.navbar_cart}>
                    {inCard.length !== 0 && <span className={style.navbar_cart_badge}>{inCard.length}</span>}
                    <ShoppingCart fill="#fff" width={25} height={25} />
                </span>
            </div>




            <div
                className={`${style.mobile_elements_nav_container} ${`${show && style.mobile_elements_nav_container_active} `}`}
            >
                <div
                    className={`${style.mobile_elements_nav_items} ${`${show && style.mobile_elements_nav_items_active}`}`}
                >
                    <span className={style.mobile_elements_nav_items_close} onClick={() => setShow(prev => !prev)} >
                        <CloseIcon fill="#fff" width={25} height={25} />
                    </span>
                    <ul >
                        {items.map((x, index) => <li onClick={() => handleClick(x.route)} className={style.mobile_elements_nav_items_li} key={index}>{x.name}</li>)}
                    </ul>
                </div>
            </div>

        </nav>

    )
}

const mapStateToProps = state => {

    return {
        inCard: state.inCard,
        isCartMenuActivated: state.isCartMenuActivated
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setActive: (props) => {
            dispatch({ type: "CART_MENU_ACTIVE", status: !(props.isCartMenuActivated) })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)