import React, { useState } from "react"
import { connect } from 'react-redux'

function GenderBar({ updateFilter }) {

    const handleSelectChange = (e) => {
        updateFilter(e.target.value)
    }

    const categories = [
        'Niños', 'Terror', 'Carreras', 'Shooters', 'Peleas', 'Mundo abierto', 'Aventuras', 'Deportivos'
    ]

    return (
        <div className="filters">

            <div className="container-select-categorie">
                <select className="select-categorie" onChange={handleSelectChange}>
                    <option value="">Selecciona genero</option>
                    {
                        categories.map(item => <option value={item} key={item} >{item}</option>)
                    }
                </select>
            </div>

        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateFilter: (f) => {
            dispatch({ type: "UPDATE_FILTER", item: f })
        }
    }
}

const mapStateToProps = state => {
    return {
        filters: state.filters,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GenderBar)
