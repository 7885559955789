import React from 'react'
import Button from 'src/components/Button'
import style from "./style.module.css"


export default function CartButton(props) {
    const handleClick = () => {

        if (props.sectionCart === 'pedido') {
            if (props.inCard.length !== 0) {
                props.setSectionCart('Checkout')
            } else {
                console.log('cart empty')
            }
        } else {
            props.handleSetSection('confirmar')
        }
    }
    return (
        <div className={style.cartButton} >
            <div   >
                <div  >
                    {
                        props.sectionCart === 'confirmar' ? <>
                            {
                                props.loading ? <></> : <Button onClick={props.addOrder} style={{ margin: 0 }}>
                                    Finalizar Pedido
                                </Button>
                            }

                        </> : <>
                            <Button onClick={handleClick} style={{ margin: 0 }}>
                                Siguiente
                            </Button>
                        </>
                    }

                </div>
            </div>
        </div>
    )
}