
export default function SearchIcon({ width, height, ...props }) {
    return (
        <svg
            width={width}
            height={height}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="m18.13 17.17-2.865-2.801-.067-.102a.672.672 0 0 0-.947 0c-2.435 2.233-6.186 2.355-8.766.284-2.58-2.072-3.189-5.692-1.422-8.462 1.767-2.77 5.36-3.828 8.398-2.473 3.037 1.354 4.576 4.701 3.595 7.821a.649.649 0 0 0 .151.643c.165.172.41.246.646.193a.669.669 0 0 0 .493-.45c1.173-3.702-.598-7.686-4.166-9.375C9.612.76 5.317 1.873 3.074 5.068.831 8.265 1.313 12.583 4.208 15.23c2.894 2.648 7.334 2.83 10.445.43l2.537 2.48a.682.682 0 0 0 .947 0 .653.653 0 0 0 0-.934l-.007-.037Z"
                fill="#fff"
            />
        </svg>
    )
}



