import { useState } from "react"
import { useLocation } from "wouter"
import style from "./style.module.css"

export default function CardArticle({ title, img, name, section, price, id, sale_price, cod, ...props }) {

    const [, navigate] = useLocation()

    const [uploadedImage, setUploadedImage] = useState(false)

    const fit = () => {
        if (section === 'FUNKOS') {
            return "cover"
        } else if (section === 'CONTROLES-ACCESORIOS') {
            return "cover"
        } else if (section === 'CONSOLAS') {
            return "cover"
        }
        return ''
    }

    return (
        <article className={style.cardArticle} onClick={() => navigate(`/detail/${id}`)}>

            {!uploadedImage && <div className={style.images_is_loading}></div>}
            <img
                className={style.cardArticle_img}
                loading="lazy"
                src={img}
                style={!uploadedImage ? { display: 'hidden', objectFit: `${fit()}` } : { borderRadius: "8px", objectFit: `${fit()}` }}
                onLoad={() => setUploadedImage(true)}
            />

            <div className={style.cardArticle_information}>
                <span className={style.cardArticle_information_title}>{name}</span>
                {
                    cod === '' ? <>
                        {title !== 'NSWITCH' && <span>US ${price.toFixed(2)}</span>}
                        {/* <span>US ${price.toFixed(2)}</span> */}
                    </> : <>

                    {title !== 'NSWITCH' && <>
                    <span>Codigo {Number(cod) === 0 ? "AGOT" : `$${Number(cod).toFixed(2)}`}</span>
                        <span>Principal {sale_price === 0 ? "AGOT" : `$${sale_price.toFixed(2)}`}</span>
                        <span>Alquiler  {price === 0 ? "AGOT" : `$${price.toFixed(2)}`} </span>
                    </>}
                    </>
                }
            </div>
            {section.includes('ALQUILER') && <div className={style.alert}>ALQUILER</div>}
        </article>
    )
}
