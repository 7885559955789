import React from 'react'
import style from "./style.module.css"

export default function ConfirmOrder({ values, typeOrder, inCard, zones }) {

    const handleClick = (e) => {
        e.currentTarget.classList.toggle('active')
        const response = e.currentTarget.querySelector('.response')
        const heightReal = response.scrollHeight
        if (!response.style.maxHeight) {
            response.style.maxHeight = heightReal + 'px'
            response.style.opacity = 1
        } else {
            response.style.maxHeight = null
        }
    }

    const subTotal = Object.values(inCard).reduce(
        (acc, { piece, price }) => acc + piece * parseFloat(price), 0
    )

    const priceDelivery = zones.find(zone => zone.addres === values.localidad)
    const total = subTotal + (typeOrder === 'recoger' ? 0 : priceDelivery.price)

    return (
        <div className={style.confirmOrder_container} >
            <p >Resumen del pedido</p>
            <div >
                <p className={style.t_left}>{values.nombre}</p>
                <p className={style.t_left}>{values.telefono}</p>

                <div >
                    <div className={style.row_detail}>
                        <p className={style.t_left}>Tipo de pedido</p>
                        <p className={style.t_right}>{typeOrder === 'recoger' ? 'Recorger en local' : 'Delivery'}</p>
                    </div>
                    <div className={style.row_detail}>
                        <p className={style.t_left}>Subtotal</p>
                        <p className={style.t_right}>${subTotal.toFixed(2)}</p>
                    </div>
                    {
                        typeOrder === 'delivery' ? <div className={style.row_detail}>
                            <p className={style.t_left}>Delivery</p>
                            <p className={style.t_right}>${priceDelivery.price.toFixed(2)}</p></div> : <></>
                    }
                    <div className={style.row_detail}>
                        <p className={style.t_left}>Total</p>
                        <p className={style.t_right}>${total.toFixed(2)}</p>
                    </div>
                </div>
                <div >
                    <div className={style.row_detail}>
                        <p className={style.t_left}>Metodo de pago</p>
                        <p className={style.t_right}>{values.metodoPago}</p>
                    </div>
                    <div className={style.note}>
                        <span>Nota: Confirme los detalles de su pedido antes de finalizar</span>
                    </div>
                    <div className="container-detail-des" onClick={(e) => handleClick(e)}>
                        <p className="t-left des"> Items del pedido <b>+</b> </p>
                        <div className="response">
                            {
                                inCard.map(doc =>
                                    <div className={style.row_detail}>
                                        <p className={style.t_left}>{doc.brand}</p>
                                        <p className={style.t_right}>{doc.piece} X ${doc.price} </p>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    {
                        typeOrder === 'delivery' ? <>
                            <div className="container-detail-des" onClick={(e) => handleClick(e)}>
                                <p className="t-left des"> Direccion <b>+</b></p>
                                <div className="response">
                                    <div className={style.row_detail}>
                                        <p className={style.t_left}>Direccion</p>
                                        <p className={style.t_right}>{values.direccion}</p>
                                    </div>
                                    <div className={style.row_detail}>
                                        <p className={style.t_left}>Numero</p>
                                        <p className={style.t_right}>{values.casa}</p>
                                    </div>
                                    <div className={style.row_detail}>
                                        <p className={style.t_left}>Localidad</p>
                                        <p className={style.t_right}>{values.localidad}</p>
                                    </div>
                                </div>
                            </div>
                        </> : <></>
                    }

                </div>
            </div>
        </div>
    )
}