import Search from "../Foms/Search"
import P from "../Text/P"
import Title from "../Text/Title"
import HeroCategories from "./HeroCategories"
import style from "./style.module.css"
import Trending from "./Trending"
import { useRef, useEffect } from "react"

export default function Hero() {
    const video = useRef()

    useEffect(() => {
        if (!video.current) return

        const { current: videoEl } = video
        videoEl.play()

    }, [video.current])
    return (
        <div className={style.hero}>
            <video
                autoPlay
                loop
                type="video/mp4"
                muted
                controls={false}
                ref={video}
                className={style.hero_video}
                src="/video.mp4"
            ></video>
            <div className={style.hero_content}>
                <HeroCategories />
                <div>
                    <Title>Explora juegos, funkos y más!</Title>
                    <P className={style.hero_content_p} >El hogar de juegos, promociones y consolas</P>
                </div>
                <div>
                    <Search />
                </div>
                <div>
                    <Trending />
                </div>
            </div>
        </div>
    )
}