import ArrowDown from "../../icons/ArrowDown";
import CardImgFull from "../Cards/CardImgFull";
import style from "./style.module.css"
export default function Popular() {

    const items = [
        // { name: "FUNKOS", noName: true, route: "FUNKOS", img: "https://firebasestorage.googleapis.com/v0/b/panaobrero.appspot.com/o/luigidigital%2FFUNK.jpeg?alt=media&token=e81d761a-b42f-452c-aba7-50c6c3fcea5a" },
        { name: "PS4", route: "PS4", img: "https://firebasestorage.googleapis.com/v0/b/panaobrero.appspot.com/o/luigidigital%2Fps4_1x.webp?alt=media&token=be168af4-303a-4441-82bf-5049b1603e6a" },
        { name: "PS5", route: "PS5", img: "https://firebasestorage.googleapis.com/v0/b/panaobrero.appspot.com/o/luigidigital%2FRECURSOS%20DE%20PAGINA%2Fps5.jpg?alt=media&token=d029b842-1bf6-429a-a5d0-99b548398e85" },
        // { name: "ALQUILER DE JUEGOS PS4 Y PS4" , route: "ALQUILER-PS", img: "https://firebasestorage.googleapis.com/v0/b/panaobrero.appspot.com/o/luigidigital%2Fps4.png?alt=media&token=c3cc4fa1-9598-415a-a1f8-75b803f9b626" },
         { redirect: true, name: "XBOX ONE Y SERIES S/X", route:"https://online.fliphtml5.com/zdaez/tkcv/", img: "https://firebasestorage.googleapis.com/v0/b/panaobrero.appspot.com/o/luigidigital%2Fxbox%20one%20xbox%20series.jpg?alt=media&token=62759a3d-5f38-4b05-a596-69ad9c7b47ef" },
        // { name: "ALQUILER DE JUEGOS XBOX ONE Y SERIES S/X" , route: "ALQUILER", img: "https://firebasestorage.googleapis.com/v0/b/panaobrero.appspot.com/o/luigidigital%2Fxbox%20one%20xbox%20series.jpg?alt=media&token=62759a3d-5f38-4b05-a596-69ad9c7b47ef" },
        { name: "NINTENDO SWITCH", route: "NSWITCHF", img: "https://firebasestorage.googleapis.com/v0/b/panaobrero.appspot.com/o/luigidigital%2Fimage_processing20200908-12254-1eumqhh.png?alt=media&token=f30c2207-f1ea-435f-b1b9-8e8c2f114629" },
        // { name: "ALQUILER NINTENDO SWITCH", route: "NSWITCH", img: "https://firebasestorage.googleapis.com/v0/b/panaobrero.appspot.com/o/luigidigital%2Fimage_processing20200908-12254-1eumqhh.png?alt=media&token=f30c2207-f1ea-435f-b1b9-8e8c2f114629" },
        // { name: "JUEGOS RETRO", route: "JRETRO", img: "https://firebasestorage.googleapis.com/v0/b/panaobrero.appspot.com/o/luigidigital%2F4a1cacbe919300b15645482f7cac3aad.webp?alt=media&token=60215bb9-959a-4184-8876-673e8c2d7e42" },
        { name: "CONTROLES Y ACCESORIOS", route: "CONTROLES-ACCESORIOS", img: "https://firebasestorage.googleapis.com/v0/b/panaobrero.appspot.com/o/luigidigital%2F2d5a5dabcd9587ee812bd4a0135c1ac6.webp?alt=media&token=2e4597eb-25a2-4f6d-a135-7ab3aa27b0d3" },
        { name: "CONSOLAS", route: "CONSOLAS", img: "https://firebasestorage.googleapis.com/v0/b/mazasocial.appspot.com/o/CONSOLAS.webp?alt=media&token=9dfea89e-e458-4064-b646-669a0884ae77" },
        // { name: "SUSCRIPCIONES & GIFT CARDS", noInDesktop: true, route: "SUSCRIPCIONES-GIFT-CARDS", img: "https://firebasestorage.googleapis.com/v0/b/panaobrero.appspot.com/o/luigidigital%2F4a7a29894e86e947e8c724b2ea9e0686.webp?alt=media&token=02ece3f5-af39-4597-aa70-4b5170e9f805" },
    ]
    
    return (
        <div className={style.popular}>
            <span>Popular <ArrowDown width="15px" height="15px" /></span>
            <div className={style.popular_items}>
                {
                    items.map((x, index) => !x.noInDesktop && <CardImgFull redirect={x.redirect?true:false} noName={x.noName} key={index} img={x.img} name={x.name} route={x.route} />)
                }
            </div>
        </div>
    )
}
