import Home from "./pages/home";
import Section from "./pages/section"
import { Route, Switch } from 'wouter';
import Detail from "./pages/Detail";
import { Provider } from "react-redux";
import store from "./redux/store";
import Cart from "./pages/Cart";
import SearchPage from "./pages/Search";

function App() {
  return (
    <Provider store={store}>
      <Switch>
        <Route
          path="/"
          component={Home}
        />
        <Route
          path="/cart"
          component={Cart}
        />
        <Route
          path="/section/:id"
          component={Section}
        />
        <Route
          path="/search/:id"
          component={SearchPage}
        />
        <Route
          path="/detail/:id"
          component={Detail}
        />
      </Switch>
    </Provider>

  );
}

export default App;
