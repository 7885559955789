// import CardArticle from "../Cards/CartArticle";

// export default function Articles({ articles }) {
//     return (
//         <div className={style.articles}>
//             {
//                 articles.map((x, index) => <CardArticle key={index} id={x.id} img={x.cover_photo} name={x.product_name} price={x.price} />)
//             }
//         </div>
//     )
// }

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import CardArticle from "../Cards/CartArticle";
import Spinner from '../Loading';
import style from "./style.module.css"

function Articles({ ...props }) {

    const [articles, setArticles] = useState([])

    const [typeGame, setTypeGame] = useState('')
    const [loading, setLoading] = useState(true)

    const handleSelectChange = (e) => {
        setTypeGame(e.target.value)
    }

    const standars = [
        'Preventa', 'Estreno', 'Populares', 'Estandar'
    ]

    const calculePriceForStandar = (doc) => {
        switch (doc) {
            case 'Preventa':
                return `${doc} $10`
            case 'Estreno':
                return `${doc} $10`
            case 'Populares':
                return `${doc} $8`
            case 'Estandar':
                return `${doc} $5`
            default:
                return ''
        }
    }

    // Función de comparación personalizada para ordenar por el número en el nombre
    function compararPorNumeroEnNombre(a, b) {
        // Extraer los números del principio de los nombres
        const numeroA = obtenerNumero(a.product_name);
        const numeroB = obtenerNumero(b.product_name);

        // Comparar los números y devolver el resultado
        return numeroA - numeroB;
    }

    // Función para obtener el número al principio del nombre o devolver Infinity si no hay número
    function obtenerNumero(nombre) {
        const match = nombre.match(/^\d+/);
        return match ? parseInt(match[0]) : Infinity;
    }

    useEffect(() => {
        if (!props.loading) {

            if (props.gender.length !== 0) {
                if (!loading) setLoading(true)
                setTimeout(() => {
                    // setArticles(
                    //     props.articles.sort((a, b) => {
                    //         return b.price - a.price
                    //     }).
                    //         filter(item => item.gender === props.gender.toUpperCase())
                    // )
                    setArticles(
                        props.articles.sort(compararPorNumeroEnNombre)
                            .filter(item => item.gender === props.gender.toUpperCase())
                    )
                    setLoading(false)
                }, 1500)
            } else {
                if (!loading) setLoading(true)
                setTimeout(() => {
                    props.updateGender('FISICO')
                    // setArticles(
                    //     props.articles.sort((a, b) => {
                    //         return b.price - a.price
                    //     })
                    // )
                    setArticles(
                        props.articles.sort(compararPorNumeroEnNombre)
                    )
                    setLoading(false)
                }, 1500)

            }
        }

    }, [props.gender, props.loading])

    useEffect(() => {
        if (props.gender.length === 0) {
            // setArticles(
            //     props.articles.sort((a, b) => {
            //         return b.price - a.price
            //     })
            // )
            setArticles(
                props.articles.sort(compararPorNumeroEnNombre)
            )
        }
    }, [props])

    // if (loading) return <div><Spinner /></div>

    // console.log(props.articles)

    if (loading) return <div><Spinner /></div>

    return (
        <>


            {
                props.gender === 'DIGITAL' ? <>

                    {
                        props.title === 'XONE-XSERIES' ? <>
                            <div style={{ marginTop: '10px' }}>
                                <div >
                                    <select onChange={handleSelectChange}>
                                        <option value="">Selecciona tipo de juego</option>
                                        {
                                            standars.map(doc => <option value={doc} key={doc}>{calculePriceForStandar(doc)}</option>)
                                        }
                                    </select>
                                </div>
                            </div>

                        </> : <></>
                    }

                </> : <></>
            }

            <div style={{ marginBottom: '40px' }} >
                <div >
                    {
                        articles.length === 0 ? <p style={{ textAlign: "center" }}> <span> LuigiDigital no ha encontrado tu busqueda </span></p> :
                            <div>
                                {props.gender === 'DIGITAL' ?
                                    <h5 style={{ fontSize: '17px' }}>{props.title !== 'NSWITCH' && props.title}</h5>
                                    : <></>}

                                {
                                    props.title === 'XONE-XSERIES' ?
                                        <>

                                            {
                                                props.gender === 'DIGITAL' ?
                                                    <>
                                                        {
                                                            typeGame.length === 0 ? <>
                                                                {standars.map((doc, index) =>
                                                                    articles.some(item => item.cod === doc) ?
                                                                        <>
                                                                            {/* {console.log(articles.reverse())} */}
                                                                            <h5 className="categorie">{calculePriceForStandar(doc)}</h5>
                                                                            {doc === 'Estandar' ? <>
                                                                                {articles.reverse().map(item =>
                                                                                    item.cod === doc ?
                                                                                        <CardArticle
                                                                                            bgColor="#f6f6f6s"
                                                                                            image={item.cover_photo === 'undefined' ? 'https://firebasestorage.googleapis.com/v0/b/panaobrero.appspot.com/o/luigidigital%2FIMGDEFAULT-min.png?alt=media&token=a6ab7f1a-7d35-42bb-920d-4dc495ceb811' : item.cover_photo}
                                                                                            href={`/article/${item.id}`}
                                                                                            gender={item.gender}
                                                                                            name={item.product_name}
                                                                                            section={item.section}
                                                                                            price={item.price}
                                                                                            title={props.title}
                                                                                            position={index}
                                                                                            item={item}
                                                                                            sale_price={item.sale_price}
                                                                                            cod={item.cod}
                                                                                            start={item.start}
                                                                                        />
                                                                                        : <></>
                                                                                )}

                                                                            </> : <>
                                                                                {articles.map(item =>
                                                                                    item.cod === doc ?
                                                                                        <CardArticle
                                                                                            bgColor="#f6f6f6s"
                                                                                            image={item.cover_photo === 'undefined' ? 'https://firebasestorage.googleapis.com/v0/b/panaobrero.appspot.com/o/luigidigital%2FIMGDEFAULT-min.png?alt=media&token=a6ab7f1a-7d35-42bb-920d-4dc495ceb811' : item.cover_photo}
                                                                                            href={`/article/${item.id}`}
                                                                                            gender={item.gender}
                                                                                            name={item.product_name}
                                                                                            title={props.title}
                                                                                            section={item.section}
                                                                                            price={item.price}
                                                                                            position={index}
                                                                                            item={item}
                                                                                            sale_price={item.sale_price}
                                                                                            cod={item.cod}
                                                                                            start={item.start}
                                                                                        />
                                                                                        : <></>
                                                                                )}
                                                                            </>}

                                                                        </> : '')
                                                                }
                                                            </> : <>

                                                                {
                                                                    articles.some(item => item.cod === typeGame) ?
                                                                        <>
                                                                            <h5 className="categorie">{typeGame}</h5>
                                                                            {articles.map(item =>
                                                                                item.cod === typeGame ?
                                                                                    <>
                                                                                        <CardArticle
                                                                                            bgColor="#f6f6f6s"
                                                                                            image={item.cover_photo === 'undefined' ? 'https://firebasestorage.googleapis.com/v0/b/panaobrero.appspot.com/o/luigidigital%2FIMGDEFAULT-min.png?alt=media&token=a6ab7f1a-7d35-42bb-920d-4dc495ceb811' : item.cover_photo}
                                                                                            href={`/article/${item.id}`}
                                                                                            gender={item.gender}
                                                                                            name={item.product_name}
                                                                                            title={props.title}
                                                                                            section={item.section}
                                                                                            price={item.price}
                                                                                            item={item}
                                                                                            sale_price={item.sale_price}
                                                                                            cod={item.cod}
                                                                                            start={item.start}
                                                                                        />
                                                                                    </>
                                                                                    : <></>
                                                                            )}
                                                                        </> : <></>
                                                                }
                                                            </>
                                                        }

                                                    </>
                                                    : <>
                                                        <div className={style.articles}>
                                                            {articles.map((x, index) =>
                                                                <>
                                                                    <CardArticle
                                                                        img={x.cover_photo === 'undefined' ? 'https://firebasestorage.googleapis.com/v0/b/panaobrero.appspot.com/o/luigidigital%2FIMGDEFAULT-min.png?alt=media&token=a6ab7f1a-7d35-42bb-920d-4dc495ceb811' : x.cover_photo}
                                                                        key={index}
                                                                        section={x.section}
                                                                        id={x.id}
                                                                        name={x.product_name}
                                                                        price={x.price}
                                                                        sale_price={x.sale_price}
                                                                        title={props.title}
                                                                        cod={x.cod}
                                                                    />
                                                                </>
                                                            )}
                                                        </div>
                                                    </>
                                            }

                                        </>
                                        :
                                        <>
                                            <div className={style.articles}>
                                                {articles.map((x, index) =>
                                                    <>
                                                        <CardArticle
                                                            img={x.cover_photo === 'undefined' ? 'https://firebasestorage.googleapis.com/v0/b/panaobrero.appspot.com/o/luigidigital%2FIMGDEFAULT-min.png?alt=media&token=a6ab7f1a-7d35-42bb-920d-4dc495ceb811' : x.cover_photo}
                                                            key={index}
                                                            id={x.id}
                                                            name={x.product_name}
                                                            section={x.section}
                                                            title={props.title}
                                                            price={x.price}
                                                            sale_price={x.sale_price}
                                                            cod={x.cod}
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        </>
                                }

                            </div>

                    }
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        inCard: state.inCard,
        gender: state.gender,
        isCartMenuActivated: state.isCartMenuActivated
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setActive: (props) => {
            dispatch({ type: "CART_MENU_ACTIVE", status: !(props.isCartMenuActivated) })
        },
        setCart: () => {
            dispatch({ type: "CLEAR_CART" })
        },
        updateGender: (f) => {
            dispatch({ type: "UPDATE_GENDER", item: f })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Articles)
