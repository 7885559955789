import * as React from "react"

const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 210 210"
        style={{
            enableBackground: "new 0 0 210 210",
        }}
        xmlSpace="preserve"
        {...props}
    >
        <path d="M75 0H15C6.716 0 0 6.716 0 15v60c0 8.284 6.716 15 15 15h60c8.284 0 15-6.716 15-15V15c0-8.284-6.716-15-15-15zM75 120H15c-8.284 0-15 6.716-15 15v60c0 8.284 6.716 15 15 15h60c8.284 0 15-6.716 15-15v-60c0-8.284-6.716-15-15-15zM195 0h-60c-8.284 0-15 6.716-15 15v60c0 8.284 6.716 15 15 15h60c8.284 0 15-6.716 15-15V15c0-8.284-6.716-15-15-15zM195 120h-60c-8.284 0-15 6.716-15 15v60c0 8.284 6.716 15 15 15h60c8.284 0 15-6.716 15-15v-60c0-8.284-6.716-15-15-15z" />
    </svg>
)

export default SvgComponent
