import React from 'react'
import Input from '../Foms/Input'
import Select from '../Foms/Select'
import style from "./style.module.css"

export default function Checkout({ handleInputChange, typeOrder, setTypeOrder, values, zones }) {
    return (
        <div className={style.checkout_container} >
            <div>
                <p >Tipo de pedido</p>
                <div className={style.checkout_container_orderTypes} >
                    <div className={`${style.checkout_container_orderTypes_type} ${typeOrder === "delivery" && style.checkout_container_orderTypes_type_active} `} onClick={() => setTypeOrder('delivery')}>
                        <span>Delivery</span>
                    </div>
                    <div className={`${style.checkout_container_orderTypes_type} ${typeOrder === "recoger" && style.checkout_container_orderTypes_type_active} `} onClick={() => setTypeOrder('recoger')}>
                        <span>Digital o Para recoger en el local</span>
                    </div>
                </div>
            </div>
            <div >
                {
                    typeOrder === 'delivery' ? <>
                        <form>
                            <p >Sus datos</p>
                            <label>Nombre y apellido</label>
                            <Input value={values.nombre} name="nombre" onChange={handleInputChange} />
                            <label>Telefono</label>
                            <Input value={values.telefono} name="telefono" onChange={handleInputChange} />
                            <p >Direccion de envio</p>
                            <label>Direccion</label>
                            <Input value={values.direccion} name="direccion" onChange={handleInputChange} />
                            <label>Numero de apartamento, casa, oficina (opcional)</label>
                            <Input value={values.casa} name="casa" onChange={handleInputChange} />
                            <label>Localidad</label>
                            <Select value={values.localidad} name="localidad" onChange={handleInputChange}>
                                <option value="">Seleccionar</option>
                                {
                                    zones.map((zone, index) =>
                                        <option key={zone.id} value={zone.addres}>
                                            {zone.addres} - ${zone.price}
                                        </option>
                                    )
                                }
                            </Select>
                            <p >Metodo de pago </p>
                            <label>Seleccione un metodo</label>
                            <Select value={values.metodoPago} name="metodoPago" onChange={handleInputChange}>
                                <option value="">Seleccionar</option>
                                <option value="Zelle">Zelle</option>
                                <option value="Paypal">Paypal</option>
                                <option value="Bolivares">Bolivares</option>
                                <option value="Banesco Panama">Banesco Panama</option>
                                <option value="Binance USDT">Binance USDT</option>
                            </Select>
                        </form>
                    </> :
                        <form>
                            <p >Sus datos</p>
                            <label>Nombre y apellido</label>
                            <Input value={values.nombre} name="nombre" onChange={handleInputChange} />
                            <label>Telefono</label>
                            <Input value={values.telefono} name="telefono" onChange={handleInputChange} />
                            <p >Metodo de pago </p>
                            <label>Seleccione un metodo</label>
                            <Select value={values.metodoPago} name="metodoPago" onChange={handleInputChange}>
                                <option value="">Seleccionar</option>
                                <option value="Zelle">Zelle</option>
                                <option value="Paypal">Paypal</option>
                                <option value="Bolivares">Bolivares</option>
                                <option value="Banesco Panama">Banesco Panama</option>
                                <option value="Binance USDT">Binance USDT</option>
                            </Select>
                        </form>
                }
            </div>
        </div>
    )
}
