import { useLocation } from "wouter"
import style from "./style.module.css"

export default function HeroCategories() {

    const [, location] = useLocation()

    const categories = [
        { name: "PS4", action: null ,id:"PS4"},
        { name: "PS5", action: null ,id:"PS5"},
    ]

    return (
        <div className={style.heroCategories}>
            {
                categories.map((x, index) => <div onClick={()=>location(`/section/${x.id}`)} key={index} className={style.heroCategories_categorie}> {x.name} </div>)
            }
        </div>
    )
}
