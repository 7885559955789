import Navbar from "../Navbar";
import WhatsappButton from "../WhatsappButton";
import style from "./style.module.css"

export default function Layout({ children, maxWidth }) {
    return (
        <main className={`${maxWidth && style.layout}`} >
            <Navbar />
            <div>
                {children}
            </div>
            <WhatsappButton/>
        </main>
    )
}