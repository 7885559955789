import Button from "src/components/Button";
import Layout from "src/components/Layout";
import P from "src/components/Text/P";
import useArticlesForId from "src/hooks/useArticleForId";
import style from "./style.module.css"
import { connect } from 'react-redux'
import Spinner from "src/components/Loading";

function Detail({ params, addItem, inCard }) {

    const id = decodeURI(params.id.toUpperCase())

    const { article, loading } = useArticlesForId(id)

    // const { recommendations: relatedProducts } = useRelatedProducts(params.id)

    const {
        brand,
        information,
        photos,
        price,
        product_name,
        sale_price,
        sizes,
        cover_photo,
        section,
        cod,
    } = article


    return (
        <Layout>
            <div className={style.detail}
            >
                {
                    loading ? <Spinner /> : <>
                        <img src={cover_photo} className={style.detail_img} />

                        <div className={style.detail_container_information}>
                            <div className={style.detail_container_imgSingle}>
                                <img className={style.detail_imgSingle} src={cover_photo} />
                            </div>
                            <div className={style.detail_information}>
                                <h3>{product_name}</h3>
                                {
                                    cod !== '' ? <>

                                        <div className={style.flex}>
                                            <span>Codigo  {Number(cod) === 0 ? "AGOT" : `$${Number(cod).toFixed(2)}`} </span>
                                            {Number(cod) === 0 ? <div></div> : <button className={style.orderItem_col_buttons_button} onClick={() => addItem({ item: { ...article, sale_price: Number(cod) }, selectedPhoto: 0, selectedSize: null, inCard: inCard })} >+</button>}
                                        </div>
                                        <div className={style.flex}>
                                            <span>Cuenta principal {sale_price === 0 ? "AGOT" : `$${sale_price.toFixed(2)}`} </span>
                                            {sale_price === 0 ? <div></div> : <button className={style.orderItem_col_buttons_button} onClick={() => addItem({ item: { ...article, sale_price: Number(sale_price) }, selectedPhoto: 0, selectedSize: null, inCard: inCard })} >+</button>}
                                        </div>
                                        <div className={style.flex}>
                                            <span>Alquiler {Number(price) === 0 ? 'AGOT' : `$${Number(price).toFixed(2)}`} </span>
                                            {Number(price) === 0 ? <div></div> : <button className={style.orderItem_col_buttons_button} onClick={() => addItem({ item: { ...article, sale_price: Number(price) }, selectedPhoto: 0, selectedSize: null, inCard: inCard })}>+</button>}
                                        </div>
                                    </> : <>
                                        {
                                            section === 'NSWITCH' && <></>
                                        }
                                        {
                                            section !== 'NSWITCH' && <span> ${price.toFixed(2)}</span>
                                        }
                                    </>
                                }

                                {
                                    cod === '' && <>
                                        <div className={style.detail_information_containerButton}>
                                            {
                                                section !== 'NSWITCH' && <Button onClick={() => addItem({ item: { ...article }, selectedPhoto: 0, selectedSize: null, inCard: inCard })}>Agregar al carrito</Button>
                                            }
                                        </div>
                                    </>
                                }

                            </div>
                            <div className={style.detail_description}>
                                <h5>Descripción</h5>
                                <P>{information}</P>
                            </div>
                        </div>


                    </>
                }

            </div>
        </Layout>
    )
}
const mapStateToProps = (state) => {
    return {
        inCard: state.inCard
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addItem: (props) => {

            const price = props.item.sale_price !== 0 ? props.item.sale_price : props.item.price
            const test = props.inCard.some(x => x.id === `${props.item.id}-${price}`)
            const customSize = props.selectedSize ? props.selectedSize : 'Por definir'

            if (!test) {
                const a = false

                if (a) {
                    dispatch({
                        type: "INCREMENT_ITEM_PIECE", item: {
                            id: `${props.item.id}-${price}`,
                            piece: 1,
                            image: props.item.photos[props.selectedPhoto],
                            size: props.selectedSize ? props.selectedSize : 'Por definir',
                        }
                    })
                } else {
                    dispatch({
                        type: "ADD_LIST", item:
                        {
                            id: `${props.item.id}-${price}`,
                            brand: props.item.product_name,
                            cod: props.item.cod,
                            price: price,
                            image: props.item.photos[props.selectedPhoto],
                            piece: 1,
                            size: props.selectedSize ? props.selectedSize : 'Por definir',
                            photo: props.selectedPhoto
                        }
                    })
                }
            } else {
                dispatch({
                    type: "INCREMENT_ITEM_PIECE", item: {
                        id: `${props.item.id}-${price}`,
                        piece: 1,
                        image: props.item.photos[props.selectedPhoto],
                        size: props.selectedSize ? props.selectedSize : 'Por definir',
                    }
                })
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Detail)

