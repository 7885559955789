import React from 'react'
import { Provider } from 'react-redux'
import store from '../../redux/store'
import useArticlesForSearch from 'src/hooks/useArticlesForSearch';
import Layout from 'src/components/Layout';
import Title from 'src/components/Text/Title';
import Search from 'src/components/Foms/Search';
import Articles from 'src/components/Articles';
import Spinner from 'src/components/Loading';

export default function SearchPage({ params }) {

    const search = decodeURI(params.id.toUpperCase())
    const { articles, loading } = useArticlesForSearch(search)


    return (
        <Provider store={store}>
            <Layout maxWidth>
                <Search />
                <Title>{search}</Title>
                <div>
                    {
                        loading ? <Spinner /> : <>
                             <Articles articles={articles} />
                        
                        </>
                    }
                </div>
            </Layout>
        </Provider>
    )
}