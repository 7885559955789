import React, { useEffect } from "react"
import { connect } from 'react-redux'
import style from "./style.module.css"


const CategoryItem = ({ name, categorie, filter, onClick, ...props }) => {



    return (
        <>
            {/* {
                name.toUpperCase() === categorie ? <>

                    <li className={style.categorieItem}
                        onClick={() => onClick(name)}
                        style={{ background: `${filter}`, color: `${filter === '#000' ? '#fff' : '#fff'}` }}>
                        <a>
                            <span >{name === 'FISICO' ? 'Fisicos' : 'Digitales'} </span>

                        </a>
                    </li>
                </> : <>
                    <li className={style.categorieItem}
                        onClick={() => onClick(name)}
                        style={{ background: `${filter}`, color: `${filter === '#000' ? '#fff' : '#fff'}` }}
                    >
                        <a>
                            <span>{name === 'FISICO' ? 'Fisicos' : 'Digitales'}  </span>

                        </a>
                    </li>
                </>
            } */}
        </>
    )
}

function CategoriesBar({ section, ...props }) {


    useEffect(() => {
        if (section === 'NSWITCH') {
            props.updateGender('DIGITAL')
        } else if (section !== 'ALQUILER-PS') {
            props.updateGender('FISICO')
        }
    }, [props])


    return (
        <ul className={style.categoriesBar} >
            <CategoryItem
                name="FISICO"
                filter={props.gender === 'FISICO' ? '#000' : '#162129'}
                categorie={props.categorie}
                onClick={props.updateGender}
            />
            <CategoryItem
                name="DIGITAL"
                filter={props.gender === 'DIGITAL' ? '#000' : '#162129'}
                categorie={props.categorie}
                onClick={props.updateGender}
            />
        </ul>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateGender: (f) => {
            dispatch({ type: "UPDATE_GENDER", item: f })
        }
    }
}

const mapStateToProps = state => {
    return {
        gender: state.gender ? state.gender : 'FISICO',
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesBar)
