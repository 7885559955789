import { useLocation } from "wouter"
import style from "./style.module.css"



export default function Trending() {

    const [, location] = useLocation()

    const itemsTrending = [
        { name: "Assasin Creed" },
        { name: "Cod" },
        { name: "Call of duty" },
        { name: "Batlefied" },
        { name: "Gta" },
        { name: "Game pass" },
        { name: "Controles" },
    ]

    return (
        <ul className={style.trending_list}>
            <li>Más buscados</li>
            {
                itemsTrending.map((x, index) => <li onClick={() => location(`/search/${x.name}`)} className={style.trending_list_item} key={index}>{x.name}</li>)
            }
        </ul>
    )
}