import CategoriesBar from "./CategoriesBar";
import GenderBar from "./GenderBar";

export default function Filters({section}) {
    return (
        <>
            <CategoriesBar section={section} />
            {/* <GenderBar /> */}
        </>
    )
}