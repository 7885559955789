import { useState } from "react"
import { useLocation } from "wouter"
import SearchIcon from "../../../icons/Search"
import style from "./style.module.css"

export default function Search() {

    const [value, setValue] = useState("")
    const [, location] = useLocation()

    const handleInputChange = (e) => {
        setValue(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        location(`/search/${value}`)
    }

    return (
        <>
            <div className={style.search}>
                <form onSubmit={handleSubmit}>
                    <input className={style.inputSearch}
                        onChange={handleInputChange}
                    />
                    <button className={style.inputSearch_icon}><SearchIcon fill="#fff" width="20px" height="20px" /></button>
                </form>
            </div>
        </>
    )
}